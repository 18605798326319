import React from 'react';
import styled from 'styled-components';

const Section = styled.div`
  display: flex;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-top: 100px;
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  @media only screen and (max-width: 768px){
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
`;

const TitleWrapper = styled.div`
  margin-bottom: 30px;
`;

const Title = styled.h1`
  -webkit-text-size-adjust: 100%;
  color: #fff;
  -webkit-box-direction: normal;
  box-sizing: border-box;
  margin: .67em 0;
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 55px;
  margin-top: 10px;
  flex: 1;
  font-size: 3.7em;
`;

const Subtitle = styled.p`
  margin-top: 20px;
  margin-bottom: 10px;
  color: #999;
  font-size: 1.3em;
  line-height: 28px;
  font-weight: 300;
  text-align: justify;
`;

const Dot = styled.span`
font-weight: bold;
background-image: linear-gradient(90deg, #0097b2, #7ed957);
-webkit-background-clip: text;
background-clip: text;
-webkit-text-fill-color: transparent;
color: transparent;
`;

const ItemContainer = styled.div`
  color: #fff;
  position: relative;
  padding-top: 34px;
  padding-right: 11px;
  flex: 1;
`;

const ItemTitle = styled.h3`
  font-weight: bold;
  font-size: 24px;
  position: relative;
  left: -16px;
  height: 10px;
  margin-top: -5px;
  margin-bottom: 0px;
  padding-top: 1px;
  padding-bottom: 3px;
  padding-left: 17px;
  color: #fff;
  line-height: 20px;

  @media only screen and (max-width: 768px){
    font-size: 18px;
    height: 22px;
  }
`;

const Number = styled.h1`
  margin: .67em 0;
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 44px;
  margin-top: 20px;
  position: absolute;
  left: -30px;
  top: -5px;
  color: #1a1919;
  font-size: 80px;
`;

const TopItem = styled.h1`
  position: relative;
  margin-top: 0px;
  padding-top: 35px;
  padding-bottom: 0px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 300; 
`;

const Item = styled.h1`
  margin-top: 8px;
  margin-bottom: 0px;
  padding-top: 0px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 300; 
`;

const CustomSoftware = () => {

  return (
    <Section id="CUSTOM">
      <Container>
        <TitleWrapper>
          <Title>Nossos Serviços<Dot>+</Dot></Title>
          <Subtitle>
            Nossa equipe de desenvolvimento altamente qualificada pode ajudá-lo em todas as suas necessidades tecnológicas
          </Subtitle>
        </TitleWrapper>
        <GridWrapper>
          <ItemContainer>
            <Number>01</Number>
            <ItemTitle>Desenvolvimento de Aplicativos Web</ItemTitle>
            <TopItem>Desenvolvemos aplicativos web escaláveis e de alto desempenho que oferecem funcionalidades avançadas e uma experiência de usuário envolvente</TopItem>
          </ItemContainer>
          <ItemContainer>
            <Number>02</Number>
            <ItemTitle>Aplicativos para Celular</ItemTitle>
            <TopItem>Desenvolvemos aplicativos móveis personalizados para iOS e Android, proporcionando experiências intuitivas e funcionais para seus usuários</TopItem>
          </ItemContainer>
          <ItemContainer>
            <Number>03</Number>
            <ItemTitle>Desenvolvimento de Software</ItemTitle>
            <TopItem>Oferecemos soluções de software sob medida para atender às necessidades específicas do seu negócio, desde aplicativos de desktop até sistemas de gestão empresarial</TopItem>
          </ItemContainer>
          <ItemContainer>
            <Number>04</Number>
            <ItemTitle>Consultoria em TI</ItemTitle>
            <TopItem>Nossa equipe especializada em tecnologia fornece orientação e suporte estratégico para ajudar sua empresa a otimizar processos, reduzir custos e aumentar a eficiência operacional</TopItem>
          </ItemContainer>
          <ItemContainer>
            <Number>05</Number>
            <ItemTitle>Desenvolvimento de E-commerce</ItemTitle>
            <TopItem>Criamos lojas virtuais robustas e seguras, proporcionando uma experiência de compra online conveniente e segura para seus clientes</TopItem>
          </ItemContainer>
          <ItemContainer>
            <Number>06</Number>
            <ItemTitle>Desenvolvimento de Sites</ItemTitle>
            <TopItem>Criamos sites responsivos e visualmente atraentes que cativam seu público-alvo e refletem a identidade da sua marca</TopItem>
          </ItemContainer>
        </GridWrapper>
      </Container>
    </Section>
  );
}

export default CustomSoftware;