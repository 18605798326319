import React from 'react';
import styled from 'styled-components';


const Section = styled.div`
  scroll-snap-align: center;
  display: flex;
  justify-content: center;
  padding-top: 60px;
`;

const Container = styled.div`
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Row1 = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media only screen and (max-width: 768px){
    background: url(./img/pedro.png) no-repeat;
    background-size: contain;
  }
`;

const Row2 = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media only screen and (max-width: 768px){
    background: url(./img/pai-removebg-preview.png) no-repeat;
    background-size: contain;
  }
`;



const List = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media only screen and (max-width: 768px){
    align-items: center;
  }
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const Right = styled.div`
 flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  @media only screen and (max-width: 768px){
    display: none;
  }
`;

const Right2 = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const Left2 = styled.div`
 flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  @media only screen and (max-width: 768px){
    display: none;
  }
`;

const Img = styled.img`
  height: 800px;
  object-fit: contain;
  margin: auto;
  pointer-events: none;
`;

const Title = styled.h1`
 -webkit-text-size-adjust: 100%;
  color: #fff;
  box-sizing: border-box;
  margin: .67em 0;
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 90px;
  margin-top: 10px;
  z-index: 100;
  padding-top: 29px;
  padding-bottom: 29px;
  font-size: 6.5em;

  @media only screen and (max-width: 768px){
    line-height: 40px;
    margin-top: 0px;
    padding-top: 0px;
    font-size: 3em;
    padding-bottom: 0px;
    margin-bottom: 5px;
    text-align: center;
  }
`;

const TitleInverted = styled.h1`
  -webkit-text-size-adjust: 100%;
  color: #fff;
  box-sizing: border-box;
  margin: .67em 0;
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 90px;
  margin-top: 10px;
  z-index: 100;
  padding-top: 29px;
  padding-bottom: 29px;
  font-size: 6.5em;
  padding-left: 92px;

  @media only screen and (max-width: 768px){
    line-height: 40px;
    margin-top: 0px;
    padding-top: 0px;
    font-size: 3em;
    padding-bottom: 0px;
    margin-bottom: 5px;
    text-align: center;
    padding-left: 0px;
  }
`;

const HelloPage = styled.h2`
  line-height: 1px;
  -webkit-box-direction: normal;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  z-index: 10;
  display: block;
  font-size: 2em;
  font-weight: 500;

  @media only screen and (max-width: 768px){
    margin-top: 0px;
    font-size: 1.4em;
    padding-bottom: 0px;
    text-align: center;
  }
`;

const Hello = styled.h2`
  line-height: 1px;
  -webkit-box-direction: normal;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  z-index: 10;
  display: block;
  font-size: 2em;
  font-weight: 500;

  @media only screen and (max-width: 768px){
    margin-top: 380px;
    font-size: 1.4em;
    padding-bottom: 0px;
    text-align: center;
  }
`;


const HelloInverted = styled.h2`
  line-height: 1px;
  -webkit-box-direction: normal;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  z-index: 10;
  display: block;
  font-size: 2em;
  font-weight: 500;
  padding-left: 92px;

  @media only screen and (max-width: 768px){
    margin-top: 380px;
    font-size: 1.4em;
    padding-bottom: 0px;
    text-align: center;
    padding-left: 0px;
  }
`;


const Dot = styled.span`
  font-weight: bold;
  background-image: linear-gradient(90deg, #0097b2, #7ed957);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
`;

const Subtitle = styled.p`
  margin-top: 0;
  margin-bottom: 10px;
  position: relative;
  z-index: 100;
  color: #999;
  font-size: 1.3em;
  line-height: 28px;
  font-weight: 300;
  text-align: justify;
  padding-right: 92px;

  @media only screen and (max-width: 768px){
    padding-right: 0;
  }
`;

const SubtitleInverted = styled.p`
  margin-top: 0;
  margin-bottom: 10px;
  position: relative;
  z-index: 100;
  color: #999;
  font-size: 1.3em;
  line-height: 28px;
  font-weight: 300;
  text-align: justify;
  padding-left: 92px;
  text-align: justify;

  @media only screen and (max-width: 768px){
    padding-left: 0;
  }
`;




const Crew = () => {

  return (
    <Section id='PROFESSIONAL'>
      <Container>
        <Row>
          <HelloPage>
            <Dot>Nossos Lideres</Dot>
          </HelloPage>
        </Row>
        <Row1>
          <Left>
            <List>
              <Hello>
                <Dot>CEO</Dot>
              </Hello>
              <Title>
                Pedro Boaron
              </Title>


              <Subtitle>
                Apaixonado por tecnologia, com uma trajetória diversificada e internacional. Possui formação em Ciência da Computação e especialização em Arquitetura de Software, complementando sua vasta experiência em desenvolvimento de software e arquitetura de sistemas. Com habilidades sólidas em liderança de equipes, planejamento e supervisão de atividades, Pedro demonstra uma paixão pela inovação e uma determinação em superar desafios, tornando-se um ativo valioso em qualquer projeto.
              </Subtitle>
            </List>
          </Left>
          <Right>
            <Img src='./img/pedro.png' />
          </Right>
        </Row1>
        <Row2>
          <Left2>
            <Img src='./img/pai-removebg-preview.png' />
          </Left2>
          <Right2>
            <List>
              <HelloInverted>
                <Dot>CFO</Dot>
              </HelloInverted>
              <TitleInverted>
                Marcos Boaron
              </TitleInverted>

              <SubtitleInverted>
                Profissional com ampla experiência na Administração Pública, ocupando cargos de destaque em diversos órgãos e instituições públicas Federal, Estadual e Municipal. Com habilidades em planejamento, coordenação e supervisão de atividades administrativas, licitações e contratações públicas, Marcos demonstrou sua capacidade de liderança e gestão ao longo de sua carreira. Além de suas realizações no setor público, ele também possui uma formação sólida em Direito, com especializações em Direito Tributário e Direito do Trabalho, e atua como professor e palestrante em diversas instituições de ensino e eventos relacionados à área jurídica. Sua trajetória profissional e sua expertise o tornam um profissional altamente qualificado e versátil, capaz de enfrentar desafios complexos com excelência e liderança.
              </SubtitleInverted>
            </List>

          </Right2>
        </Row2>
      </Container>
    </Section>
  );
}

export default Crew;