import React from "react";
import styled from "styled-components";

const Section = styled.div`
  scroll-snap-align: center;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
width: 100%;
padding-top: 100px;
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px){
    margin-top: 50px;
  }
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  @media only screen and (max-width: 768px){
    align-items: center;
    text-align: center;
  }
`;

const TitleWrapper = styled.div`
  -webkit-text-size-adjust: 100%;
  font-size: 1.4em;
  line-height: 20px;
  -webkit-box-direction: normal;
  box-sizing: border-box;
  font-weight: bold;
  display: inline-block;
  background-image: linear-gradient(90deg, #0097b2, #7ed957);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  letter-spacing: 2px;
  text-transform: uppercase;

  @media only screen and (max-width: 768px){
    font-size: 1.2em;
    text-align: center;
  }
`;

const Subtitle = styled.h1`
    margin-top: 20px;
 @media only screen and (max-width: 768px){
    margin-top: 10px;
    text-align: center;
  }
`;

const Paragraph = styled.p`
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 10px;
  position: relative;
  z-index: 100;
  padding-right: 0px;
  color: #999;
  font-size: 1.3em;
  line-height: 28px;
  font-weight: 300;
  padding-top: 55px;
  text-align: justify;
`;


const Valores = () => {

  return (
    <Section id="SKILL">
      <Container>
        <Left>
          <div>
            <TitleWrapper>
              <strong class="bold-text">Nossos Valores</strong>
            </TitleWrapper>
            <Subtitle>Unindo Tradição Familiar e Inovação Tecnológica</Subtitle>
          </div>
        </Left>
        <Right>
          <div>
            <Paragraph>
              Em nossa empresa, a liderança é guiada por indivíduos com laços estreitos, que compartilham uma visão comum e um compromisso com a excelência. Esses líderes trazem consigo não apenas a tradição e a estabilidade associadas à gestão familiar, mas também uma profunda expertise em tecnologia.
              <br />Nossa empresa combina a riqueza da experiência familiar com uma abordagem moderna e inovadora em tecnologia. Sob essa liderança, estamos constantemente explorando novas oportunidades, adotando as mais recentes tendências e ferramentas tecnológicas para impulsionar nossa empresa para frente.
              <br />Através dessa combinação única de tradição e modernidade, somos capazes de oferecer aos nossos clientes o melhor dos dois mundos: a confiabilidade e o compromisso de uma empresa com raízes familiares, aliados à inovação e à eficiência de uma empresa de tecnologia moderna.
              <br />Estamos empenhados em manter nossa reputação de excelência e liderança em nosso setor, ao mesmo tempo em que continuamos a buscar novas maneiras de agregar valor e oferecer soluções inovadoras aos nossos clientes.
            </Paragraph>
          </div>
        </Right>
      </Container>
    </Section>
  );
}

export default Valores;