import React from "react";
import * as THREE from 'three';
import { useInView } from 'react-intersection-observer';
import styled from "styled-components";
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Stage, PerspectiveCamera } from '@react-three/drei';
import LightBulb from './LightBulb';

const Section = styled.div`
  margin-top: 100px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Left = styled.div`
  flex: 1;
  
  @media only screen and (max-width: 768px){
    display: none;
  }
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  @media only screen and (max-width: 768px){
    align-items: center;
    text-align: center;
  }

`;

const Title = styled.h1`
  font-size: 64px;

  opacity: 0;
  filter: blur(5px);
  transition: opacity 3s cubic-bezier(0.16, 1, 0.3, 1),transform 3s cubic-bezier(0.16, 1, 0.3, 1);
  transform: translateX(15px);

  @media only screen and (max-width: 768px){
    font-size: 60px;
  }

  ${({ isElVisible }) => isElVisible && `
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
    animation: pulse 5s infinite;
  `}

  @keyframes pulse {
    0% {
    color: #ababab;
    }
    50% {
      color: white;
      text-shadow: 0 0 10px #fff;
    }
    100% {
      color: #ababab;
    }
  }
`;

const WhatWeDo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  opacity: 0;
  filter: blur(5px);
  transition: opacity 3s cubic-bezier(0.16, 1, 0.3, 1),transform 3s cubic-bezier(0.16, 1, 0.3, 1);
  transform: translateX(15px);

  ${({ isElVisible }) => isElVisible && `
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
  `}
`;

const Line = styled.img`
  height: 5px;
`;

const Subtitle = styled.h2`
  color: #999;
`;

const Description = styled.p`
  font-size: 24px;
  color: lightgray;

  opacity: 0;
  filter: blur(5px);
  transition: opacity 3s cubic-bezier(0.16, 1, 0.3, 1),transform 3s cubic-bezier(0.16, 1, 0.3, 1);
  transform: translateX(15px);

  ${({ isElVisible }) => isElVisible && `
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
  `}
`;

const Who = () => {
  const { ref: elRef, inView: isElVisible } = useInView({ triggerOnce: true });

  return (
    <Section>
      <Container id="WHO">
        <Left>
          <Canvas style={{ height: 700 }}>
            <OrbitControls enableZoom={false} autoRotate />
            <PerspectiveCamera
              makeDefault
              position={[6, 6, 6]}
              fov={60}
              zoom={0.9}
            />
            <Stage environment="studio" intensity={0.6}>
              <LightBulb />
            </Stage>
          </Canvas>
        </Left>
        <Right>
          <Title ref={elRef} isElVisible={isElVisible}>Descubra como
            podemos ajudá-lo a
            alcançar seus objetivos
            tecnológicos</Title>
          <WhatWeDo ref={elRef} isElVisible={isElVisible}>
          </WhatWeDo>
          <Description ref={elRef} isElVisible={isElVisible}>Desde
            desenvolvimento de software
            até consultoria em TI,
            estamos aqui para tornar
            sua visão uma realidade.</Description>
        </Right>
      </Container>
    </Section>
  );
}

export default Who;