import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faWhatsapp } from '@fortawesome/free-brands-svg-icons';


const FooterWrapper = styled.footer`
    padding: 20px 0;
`;

const Container = styled.div`
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    @media (max-width: 768px) {
        flex-direction: column;
        text-align: center;
    }
`;

const LinksWrapper = styled.div`
    ul {
        list-style: none;
        padding: 0;
        display: flex;

        li {
        margin-right: 15px;
            a {
                color: #b9b9b9;
                text-decoration: none;
            }
        }
    }
    
    @media (min-width: 768px) {
        display: none;
    }
`;



const BottomWrapper = styled.div`
    margin-top: 20px;
    padding: 10px 0;
    text-align: center;
    font-size: 14px;
    color: #b9b9b9;

    a {
        color: #555;
        text-decoration: none;
    }
`;

const SocialWrapper = styled.div`
cursor: pointer;
    display: flex;
    gap: 20px;
    align-items: center;
    ul {
        list-style: none;
        padding: 0;
        display: flex;

        li {
        margin-right: 10px;

        a {
            color: #555;
            text-decoration: none;
        }
        }
    }
`;
const handleClick = () => {
    window.open('https://wa.me/5561998904405?text=Gostaria%20de%20saber%20mais', '_blank');
  };

const Footer = () => {
    return (
        <FooterWrapper>
            <LinksWrapper>
            <SocialWrapper onClick={handleClick}>
              <h3 style={{fontSize: '16px' }}>Pode nos chamar pelo WhatsApp</h3>
              <ul>
                <FontAwesomeIcon icon={faWhatsapp} style={{ color: '#7ed957', fontSize: '32px' }} />
              </ul>
            </SocialWrapper>
            </LinksWrapper>
            <BottomWrapper>
                <p>&copy; 2024 Boaron Tecnologia da Informação LTDA. All rights reserved.</p>
                <p>Designed and developed by <a href="https://www.botisistemas.com.br">BOTI</a></p>
            </BottomWrapper>
        </FooterWrapper>
    );
};

export default Footer;