import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faWhatsapp } from '@fortawesome/free-brands-svg-icons';


const Section = styled.div`
  margin-top: 200px;
  margin-bottom: 200px;
  scroll-snap-align: center;

  @media only screen and (max-width: 768px){
    margin-top: 50px;
    margin-bottom: 50px;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media only screen and (max-width: 768px){
    justify-content: center;
  }
`;

const Title = styled.h1`
  -webkit-text-size-adjust: 100%;
  color: #fff;
  -webkit-box-direction: normal;
  box-sizing: border-box;
  margin: .67em 0;
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 70px;
  margin-top: 10px;
  -webkit-box-flex: 1;
  flex: 1;
  font-size: 3.5em;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const Input = styled.input`
  padding: 20px;
  background-color: #e4e4e4;
  color: #000;
  border: none;
  border-radius: 5px;
`;

const TextArea = styled.textarea`
  padding: 20px;
  color: #000;
  background-color: #e4e4e4;
  border: none;
  border-radius: 5px;
  resize: vertical;
`;

const Button = styled.button`
    padding: 10px 20px;
    background-image: linear-gradient(90deg, #0097b2, #7ed957);
    border: 1px solid rgb(123, 0, 132);
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    transition-duration: 0.4s;
    text-transform: uppercase;

    &:hover {
        outline: 0;
        background-color: rgba(82, 11, 90, 0.24);
    }
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 768px){
    display: none;
  }
`;

const Img = styled.img`
  height: 90px;
  object-fit: contain;
  animation: animate 2s infinite ease alternate;
  pointer-events: none;

  @keyframes animate {
    to{
      transform: translateY(-30px);
    }
  }

  @media only screen and (max-width: 768px){
    height: 500px;
  }
`;

const Dot = styled.span`
font-weight: bold;
background-image: linear-gradient(90deg, #0097b2, #7ed957);
-webkit-background-clip: text;
background-clip: text;
-webkit-text-fill-color: transparent;
color: transparent;
`;

const TitleWrapper = styled.div`
  -webkit-text-size-adjust: 100%;
  font-size: 1.4em;
  line-height: 20px;
  -webkit-box-direction: normal;
  box-sizing: border-box;
  font-weight: bold;
  display: inline-block;
  background-image: linear-gradient(90deg, #0097b2, #7ed957);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  letter-spacing: 2px;
  text-transform: uppercase;

  @media only screen and (max-width: 768px){
    text-align: center;
    margin-bottom: 35px;
  }
`;

const SocialWrapper = styled.div`
cursor: pointer;
    display: flex;
    gap: 20px;
    align-items: center;
    ul {
        list-style: none;
        padding: 0;
        display: flex;

        li {
        margin-right: 10px;

        a {
            color: #555;
            text-decoration: none;
        }
        }
    }
`;

const isEmail = (email) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

const Contact = () => {
  const form = useRef();
  const [success, setSuccess] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState();
  const [emailTouched, setEmailTouched] = useState(false);

  const checkEmail = (e) => {
    setIsEmailValid(isEmail(e.target.value));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (form.current.name.value === '' || form.current.email.value === '' || form.current.message.value === '') {
      alert('Preencha todos os campos');
      return;
    }

    if (!isEmailValid) {
      return;
    }

    emailjs.sendForm('service_wuwbezw', 'template_v4j4v0n', form.current, 'v0sbw20j1nO_Mi7Wp')
      .then((result) => {
        console.log(result.text);
        setSuccess(true);
      }, (error) => {
        console.log(error.text);
        setSuccess(false);
      });

  };

  const handleClick = () => {
    window.open('https://wa.me/5561998904405?text=Gostaria%20de%20saber%20mais', '_blank');
  };

  return (

    <Section>
      <TitleWrapper>
        <strong class="bold-text">Fale Conosco</strong>
      </TitleWrapper>
      <Container id="CONTACT">
        <Right>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 37 }}>
            <Title>Precisa de ajuda?<br /> Estamos prontos pra te atender<Dot>.</Dot></Title>
            <Img src='./img/paperPlane.png' />
            <SocialWrapper onClick={handleClick}>
              <h3>Pode nos chamar pelo WhatsApp</h3>
              <ul>
                <FontAwesomeIcon icon={faWhatsapp} style={{ color: '#7ed957', fontSize: '32px' }} />
              </ul>
            </SocialWrapper>
          </div>
        </Right>
        <Left>
          <Form ref={form} onSubmit={handleSubmit}>
            <Input name="name" placeholder="Seu Nome" />
            <Input
              name="email"
              type="email"
              placeholder="Seu Email"
              onChange={e => checkEmail(e)}
              onBlur={() => setEmailTouched(true)}
              style={emailTouched && !isEmailValid ? { color: 'red' } : null} />
            <TextArea name="message" placeholder="Como podemos te ajudar a evoluir?" rows={10} />
            <Button type="submit" disabled={success}>Enviar</Button>
            {success && <span>Responderemos em breve</span>}
          </Form>
        </Left>
      </Container>
    </Section>
  );
}

export default Contact;